        .app__works {
            flex: 1;
            width: 100%;
            flex-direction: column;
            img {
                height: 600px;
                object-fit: cover;
            }
            @media screen and (min-width: 2000px) {
                img {
                    height: 600px;
                    object-fit: cover;
                }
            }
            @media screen and (max-width: 768px) {
                img {
                    height: 500px;
                    object-fit: contain;
                }
            }
        }
        
        .app__work-filter {
            font-family: 'TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif';
            background-color: rgb(0, 0, 0);
            color: rgb(0, 0, 0);
            // height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            margin-bottom: 1rem;
            .app__work-filter-item {
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                height: 40px;
                width: 120px;
                background-color: #36454fdb;
                color: rgb(255, 255, 255);
                font-weight: 700;
                font-size: 14px;
                cursor: pointer;
                transition: all 0.3s ease;
                margin: 0.5rem;
                &:hover {
                    background-color: var(--black-color);
                    color: #fff;
                    border: 1px solid #fff;
                }
                @media screen and (min-width: 2000px) {
                    padding: 1rem 2rem;
                    border-radius: 0.85rem;
                }
                @media screen and (max-width: 768px) {
                    padding: 0;
                    margin: 0 5px 0 0;
                    font-size: 10px;
                    height: 35px;
                    font-weight: 400;
                    text-align: center;
                    line-height: 35px;
                }
            }
            .item-active {
                background-color: var(--black-color);
                color: #fff;
            }
        }
        
        #__next {
            padding: 20px;
        }
        
        .image-item {
            padding: 20px 0 20px 20px;
        }
        
        .booking {
            justify-content: center;
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            color: black;
            @media screen and (max-width: 768px) {
                p {
                    font-size: 12px;
                }
            }
        }
        
        @media screen and (max-width: 768px) {
            p.subtitle {
                font-size: 12px;
                text-align: center;
            }
        }