.container {
    //width: 100%;
    //margin-top: 4rem;
    z-index: 0;
    background-color: var(--black-color);
}

.container .carousel .slide img {
    max-height: 70vh;
}

.app__img {
    max-width: 100%;
    max-height: 100%;
}