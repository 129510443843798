.app__navbar {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0rem;
    position: absolute;
    z-index: 2;
    overflow: hidden;
    @media screen and (max-width: 768px) {
        // Background blur
        background: rgba(255, 255, 255, 0.105);
        backdrop-filter: blur(0.5px);
        -webkit-backdrop-filter: blur(.05px);
        // end here
        padding: 5px;
        display: flex;
        justify-content: space-between;
        overflow: visible;
    }
    @media screen and (min-width: 768px) and (max-width: 1079px) {
        padding: 5px;
        display: flex;
        justify-content: space-between;
        overflow: visible;
    }
}

.app__navbar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    img {
        width: 35px;
        height: 30px;
        padding: 0px;
        margin: -7px 20px 0px 30px;
        @media screen and (min-width: 2000px) {
            width: 180px;
            height: 40px;
        }
        @media screen and (min-width: 768px) and (max-width: 1079px) {
            margin-top: 0rem;
            width: 35px;
            height: 30px;
        }
        @media screen and (max-width: 768px) {
            margin: 0px 5px;
            width: 35px;
            height: 30px;
        }
    }
    a {
        text-decoration: none;
    }
    p {
        font-family: var(--font-header);
        display: inline;
        font-size: 45px;
        color: rgb(7, 7, 7);
        text-shadow: 0px 0px rgb(255, 255, 255);
        font-weight: 700;
    }
    span {
        position: absolute;
        font-size: 20px;
        word-wrap: break-word;
        margin: 45px 0 -0px -80px;
    }
    @media screen and (min-width: 768px) and (max-width: 1079px) {
        p {
            font-size: 25px;
        }
        span {
            font-size: 16px;
            margin: 30px 10px -20px -60px;
        }
    }
    @media screen and (max-width: 768px) {
        margin: 0px;
        p {
            margin: 0px;
            font-size: 25px;
        }
        span {
            font-size: 16px;
            margin: 25px 0 -20px -50px;
        }
    }
}

.app__navbar-links {
    // flex: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    li {
        margin: 0 2rem;
        cursor: pointer;
        flex-direction: column;
        div {
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;
            margin-bottom: 5px;
        }
        a {
            color: var(--white-black);
            text-decoration: none;
            flex-direction: column;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: var(--lightGray-color);
            }
        }
        &:hover {
            div {
                background: var(--gray-color);
            }
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1079px) {
        margin-left: 200px
    }
    @media screen and (max-width: 1079px) {
        display: none;
    }
}

.app__navbar-menu {
    width: 35px;
    height: 35px;
    margin-right: 20px;
    border-radius: 50%;
    border: 1px solid black;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white-color);
    svg {
        width: 70%;
        height: 70%;
        columns: var(--white-color);
    }
    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 99;
        padding: 1rem;
        width: 80%;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        // background: url('../../assets/bgWhite.png');
        background-color: var(--white-color);
        background-size: cover;
        background-repeat: repeat;
        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
        svg {
            width: 35px;
            height: 35px;
            columns: var(--secondary-color);
            margin: 0, 5rem 1rem;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            li {
                margin: 1rem;
                a {
                    color: var(--black-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        color: var(--secondary-color);
                    }
                }
            }
        }
        @media screen and (min-width:1079px) {
            display: none;
        }
    }
    @media screen and (min-width:1079px) {
        display: none;
    }
}