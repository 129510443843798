                .app__gallery {
                    -webkit-column-count: 4;
                    -moz-column-count: 4;
                    column-count: 4;
                    -webkit-column-width: 25%;
                    -moz-column-width: 25%;
                    column-width: 25%;
                    padding: 0 12px;
                    color: var(--white-color);
                    background-color: #000000;
                    @media screen and (max-width: 768px) {
                        .app__header {
                            padding: 0;
                        }
                    }
                }
                
                .lg-download {
                    display: none;
                }
                
                .gallery {
                    background-color: #000000;
                    .gallery_header {
                        color: white;
                        font-family: var(--font-header);
                        display: flex;
                        justify-content: center;
                        margin-top: 9rem;
                        text-align: center;
                        .blocker {
                            position: relative;
                            background-color: rgb(9, 9, 9);
                            z-index: 9;
                            width: 750px;
                            height: 50px;
                        }
                        h1 {
                            size: 30px;
                            margin-top: -6x;
                        }
                        @media screen and (max-width: 768px) {
                            padding: 0;
                            margin-top: 5rem;
                            h1::first-letter {
                                font-size: 130%;
                                // text-transform: uppercase;
                                // font-family: 'Times New Roman', Times, serif;
                                // font-style: italic;
                            }
                            .blocker {
                                display: none;
                            }
                        }
                    }
                }
                
                .app__gallery .pics {
                    -webkit-transition: all 350ms ease;
                    transition: all 350ms ease;
                    cursor: pointer;
                    margin-bottom: 12px;
                    border: #000000;
                    border-radius: 1;
                    @media screen and (max-width: 768px) {
                        margin: 0 0 2px 0;
                    }
                }
                
                .app__gallery .pics:hover {
                    filter: opacity(0.8);
                }
                
                @media screen and (min-width: 2000px) {
                    .app__gallery {
                        -webkit-column-count: 4;
                        //  -moz-column-count: 4;
                        column-count: 4;
                        -webkit-column-width: 25%;
                        -moz-column-width: 25%;
                        column-width: 25%;
                    }
                }
                
                @media screen and (min-width: 768px) and (max-width: 1079px) {
                    .app__gallery {
                        -webkit-column-count: 2;
                        -moz-column-count: 2;
                        column-count: 2;
                    }
                }
                
                @media screen and (max-width: 768px) {
                    .app__gallery {
                        -webkit-column-count: 3;
                        -moz-column-count: 3;
                        column-count: 3;
                        -webkit-column-width: 33.3%;
                        -moz-column-width: 33.3%;
                        column-width: 33.3%;
                        padding: 0 2px;
                        gap: 3px;
                        margin-bottom: 3px;
                    }
                }