    .app {
        background-color: var(--white-color);
        font-family: var(--font-base);
        overflow: hidden;
        // margin-top: 4rem;
    }
    
    .app__whitebg {
        background-color: var(--white-color);
    }
    
    .app__primarybg {
        background-color: var(--primary-color);
    }
    
    .app__container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: row;
    }
    
    .app__flex {
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
            display: block;
        }
    }
    
    .app__wrapper {
        flex: 1;
        width: 100%;
        flex-direction: column;
        padding: 0rem 2rem;
        @media screen and (max-width: 768px) {
            width: 90%;
            padding: 4rem 1rem 2rem;
        }
    }
    
    .copyright {
        width: 100%;
        padding: 2rem 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        p {
            text-transform: uppercase;
            color: var(--black-color);
        }
    }
    
    .head-text {
        font-size: 3rem;
        font-weight: 700;
        text-align: center;
        color: var(--black-color);
        text-transform: capitalize;
        padding: 10px 0 20px 0;
        span {
            color: var(--secondary-color);
        }
        @media screen and (min-width: 2000px) {
            font-size: 4rem;
        }
        @media screen and (max-width: 768px) {
            font-size: 2rem;
        }
    }
    
    .p-text {
        font-size: 1.2rem;
        text-align: left;
        color: var(--gray-color);
        line-height: 1.5;
        @media screen and (min-width: 2000px) {
            font-size: 1.75rem;
        }
    }
    
    .bold-text {
        font-size: 1rem;
        font-weight: 800;
        color: var(--black-color);
        text-align: left;
        @media screen and (min-width: 2000px) {
            font-size: 2rem;
        }
        @media screen and (max-width: 450px) {
            font-size: 0.9rem;
        }
    }
    
    .counters {
        padding-top: 20px;
        border-bottom: 4px solid currentColor;
        width: 70%;
        margin-left: 15%;
    }
    
    @media screen and (max-width: 768px) {
        .counters {
            padding-top: 10px;
            border-bottom: 4px solid currentColor;
            width: 30%;
            margin-left: 37%;
        }
    }
    
    .app__social {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
        div {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--white-color);
            margin: 1rem 0;
            border: 1px solid var(--lightGray-color);
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s ease-in-out;
            &:first-child {
                background-color: #c4302b;
                border-color: #c4302b;
            }
            &:nth-child(2) {
                // background-color: #3b5998;
                border-color: #3b5998;
            }
            &:nth-child(3) {
                background-color: #e95950;
                border-color: #e95950;
            }
            @media screen and (min-width: 2000px) {
                width: 70px;
                height: 70px;
                margin: 0.5rem 0;
                svg {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
    
    .app__navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // padding: 1rem;
        .app__navigation-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #cbcbcb;
            margin: 0.5rem;
            transition: background-color 0.2s ease-in-out;
            &:hover {
                background-color: var(--secondary-color);
            }
            @media screen and (min-width: 2000px) {
                width: 20px;
                height: 20px;
            }
        }
    }
    
    .app__social_Phone {
        display: none;
    }
    
    @media screen and (max-width: 768px) {
        .app__navigation,
        .app__social {
            display: none;
        }
        .app__social_Phone {
            flex-direction: row;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 1rem;
            margin-top: 0rem;
            height: 100px;
            div {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: var(--white-color);
                margin: 0.25rem 1rem;
                border: 1px solid var(--black-color);
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.3s ease-in-out;
                &:first-child {
                    background-color: #c4302b;
                    border-color: #c4302b;
                }
                &:nth-child(2) {
                    // background-color: #3b5998;
                    border-color: #3b5998;
                }
                &:nth-child(3) {
                    background-color: #e95950;
                    border-color: #e95950;
                }
                @media screen and (min-width: 2000px) {
                    width: 70px;
                    height: 70px;
                    margin: 0.5rem 0;
                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
        .copyright {
            padding: 0rem;
            margin-top: 4.5rem;
        }
    }
    
    .app__line {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }