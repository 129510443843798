.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;
    border: 1px solid #828286;
    margin: 5rem 50rem 3rem 50rem;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0;
        padding: 28px;
    }
}

.app__footer-cards {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 2rem 2rem;
    .app__footer-card {
        min-width: 290px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 1rem 0;
        padding: 1rem;
        border-radius: 10px;
        border: 1px solid #828286;
        cursor: pointer;
        background-color: #ffffff;
        transition: all 0.3s ease-in-out;
        img {
            width: 40px;
            height: 40px;
            margin: 0 0.7rem;
        }
        p {
            font-weight: 500;
        }
        a {
            text-decoration: none;
            font-weight: 500;
        }
        &:hover {
            box-shadow: 0 0 25px #6e6e6e;
        }
        @media screen and (max-width: 768px) {
            width: 90%;
        }
    }
    @media screen and (max-width: 768px) {
        width: 90%;
    }
}

.app__footer-cards .app__footer-card:last-child {
    background-color: #ffffff;
}

.app__footer-cards .app__footer-card:last-child:hover {
    box-shadow: 0 0 25px #ffffff;
}

.app__footer-form {
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;
    div {
        width: 100%;
        margin: 0.75rem 0;
        border-radius: 8px;
        border: 1px solid #828286;
        cursor: pointer;
        background-color: var(--white-color);
        transition: all 0.3s ease-in-out;
        input,
        textarea {
            width: 100%;
            padding: 0.95rem;
            border: none;
            border-radius: 7px;
            background-color: var(--white-color);
            font-family: var(--font-base);
            color: var(--secondary-color);
            outline: none;
        }
        textarea {
            height: 170px;
        }
        &:hover {
            box-shadow: 0 0 5px var(--black-color);
        }
    }
    button {
        padding: 1rem 2rem;
        border-radius: 10px;
        border: none;
        background-color: #000080;
        font-weight: 500;
        color: var(--white-color);
        outline: none;
        margin: 2rem 0 0 0;
        font-family: var(--font-base);
        transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
        cursor: pointer;
        &:hover {
            background-color: #000080;
        }
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 1rem 0;
    }
}

.footer {
    height: 400px;
    width: 100%;
    background-color: var(--primary-color);
    img {
        height: 100;
        margin-left: auto;
        margin-right: auto;
        padding: 30px;
        display: block;
    }
}

.error {
    font-size: 12px;
    color: red;
}

input::-webkit-input-placeholder {
    color: lightgray;
}

input:-moz-placeholder {
    color: gray;
}

input::-moz-placeholder {
    color: gray;
}

input:-ms-input-placeholder {
    color: gray;
}

input::-webkit-input-placeholder {
    color: gray;
}

input:-moz-placeholder {
    color: gray;
}

input::-moz-placeholder {
    color: gray;
}

input:-ms-input-placeholder {
    color: gray;
}