.wrapper {
    position: relative;
    width: 80vw;
    /* transform: translate(-50%, -50%); */
    margin-top: -4%;
    margin-left: 10%;
    display: flex;
    justify-content: space-around;
    /* gap: 10px; */
    background-color: #ffffff;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.50);
    border-radius: 15px;
}

.counter {
    width: 25vmin;
    height: 22vmin;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1em 0;
    position: relative;
    font-size: 16px;
    /* border-bottom: 10px solid #4573ea; */
}

.right_line {
    border-left: 4px solid #ccd7f3;
    height: 18vmin;
    margin-top: 1vmin;
}

i {
    color: #202A44;
    font-size: 2em;
    text-align: center;
}

span.num {
    color: #020202;
    display: grid;
    place-items: center;
    font-weight: 600;
    font-size: 1.5em;
}

span.text {
    color: #000000;
    font-size: 0.9em;
    text-align: center;
    pad: 0.7em 0;
    font-weight: 400;
    line-height: 0;
}

@media screen and (min-width: 1080px) {
    .wrapper {
        width: 80vw;
    }
    .counter {
        height: 20vmin;
        width: 26vmin;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        /* gap: 5px; */
        /* padding: 0 15px 0 15px; */
        margin: -6% 5% 0 5%;
        width: 90%;
    }
    .counter {
        width: 20%;
        height: 20vmin;
        font-size: 7px;
    }
    .right_line {
        border-left: 3px solid #ccd7f3;
    }
    span.num {
        font-size: 1.7em;
    }
    i {
        font-size: 1.7em;
    }
}