    .app__about {
        flex: 1;
        width: 100%;
        flex-direction: column;
    }
    
    .card {
        margin: 5px;
        box-shadow: none;
        // border: 1px solid #e0e0e0;
        border-radius: 5px;
        @media screen and (max-width: 768px) {
            width: 98%;
            height: 580px;
        }
        @media screen and (min-width: 768px) and (max-width: 1079px) {
            width: 260px;
        }
    }
    
    p.head-text {
        color: var(--white-black);
        font-size: 18px;
        justify-content: center;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        inline-size: 700px;
        overflow-wrap: break-word;
    }
    
    .app__profile {
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
        }
    }
    
    .app__profile-item:hover .card-image {
        transform: scale(1.15);
    }
    
    .app__profile-item {
        margin: 0rem;
        width: auto;
        height: 380px;
        background-color: #bbbbbb;
        border-radius: 5px;
        outline: 3px solid rgb(0, 0, 0);
        outline-style: auto;
        outline-offset: -10px;
        outline-width: 40px;
        // box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .85;
            transition: transform 0.75s;
        }
        .bold-text {
            font-size: 35px;
            font-weight: 400;
            font-family: var(--font-header);
            color: #fff;
            text-align: center;
        }
        .bold-text::first-letter {
            font-size: 130%;
        }
        .title {
            position: absolute;
            font-size: 20px;
            color: #fff;
            left: 50%;
            top: 70%;
            transform: translate(-50%, -70%);
            width: 200px;
            span {
                display: inline-block;
                margin: 0px 40%;
                font-size: 25px;
            }
        }
        @media screen and (min-width: 2000px) {
            margin: 0rem 1rem;
            img {
                height: 380px;
            }
        }
        @media screen and (min-width: 768px) and (max-width: 1079px) {
            width: 250px;
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            height: 450px;
            img {
                height: 500px;
            }
        }
    }
    
    .box {
        opacity: 1;
        padding-left: 10px;
        inline-size: 265px;
        overflow-wrap: break-word;
        font-family: "Poppins", sans-serif;
        font-size: 11px;
        color: black;
        box-shadow: none;
        button {
            font-weight: 700;
            border: 0;
            font-size: 12px;
            color: #000;
            background-color: #fff;
        }
        button:hover {
            background-color: #fff;
            color: rgb(47, 47, 47);
            font-size: 12.5px;
        }
    }
    
    @media screen and (max-width: 768px) {
        .box {
            // margin-right: 1rem;
            padding: z0px;
            inline-size: 350px;
            overflow-wrap: break-word;
            font-family: "Poppins", sans-serif;
            color: black;
        }
        p.head-text {
            font-size: 14px;
            inline-size: 330px;
            margin: 0px 0px 20px 10px;
            margin-bottom: -3rem;
        }
    }