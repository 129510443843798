.app__header {
    flex: 1;
    width: 100%;
    height: 90%;
    flex-direction: row;
    // background-color: var(--white-color);
    position: relative;
    @media screen and (min-width: 768px) and (max-width: 1079px) {
        flex-direction: column;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.w-100 {
    width: 100% !important;
    height: 90vh !important;
    @media screen and (max-width: 768px) {
        height: 60vh !important;
    }
}

.item {
    img {
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* Positional values */
        object-position: 50% 20%;
        @media screen and (max-width: 768px) {
            height: 60vh !important;
        }
    }
}

.app__header-info {
    flex: 1;
    display: flex;
    // margin-top: -5rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    @media screen and (max-width: 1080px) {
        width: 100%;
    }
    @media screen and (min-width: 1600px) and (max-width: 2000px) {
        margin-top: -14em;
    }
    @media screen and (min-width: 769px) and (max-width: 1599px) {
        margin-top: -8em;
    }
    @media screen and (max-width: 768px) {
        margin-top: 0rem;
        background-size: contain;
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    .badge-cmp,
    .tag-cmp {
        flex-direction: row;
        width: auto;
        .name-head-text {
            font-size: 4rem;
            font-family: var(--font-header);
            font-weight: 700;
            text-align: left;
            color: var(--white-color);
            text-transform: capitalize;
            padding: 0px;
            @media screen and (min-width: 1600px) {
                font-size: 5rem;
            }
            @media screen and (max-width: 768px) {
                font-size: 3.5rem;
            }
        }
        .p-text {
            font-size: 16px;
            color: var(--white-color);
            @media screen and (max-width: 768px) {
                font-size: 12px;
                color: var(--white-color);
            }
        }
    }
    .tag-cmp {
        flex-direction: column;
        //margin-top: 3rem;
        p {
            font-size: 16px;
            width: 100%;
            text-align: left;
            color: var(--white-color);
        }
    }
    span {
        font-size: 2.5rem;
        @media screen and (min-width: 1600px) {
            font-size: 7rem;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.item {
    @media screen and (min-width: 769px) and (max-width: 1079px) {}
    @media screen and (max-width: 768px) {
        margin-top: 0rem;
        background-size: contain;
        img {
            object-fit: cover;
        }
    }
}